<script>
import Layout from "../../layouts/main";
const Area = require('./../../../api/area');
const SoalType = require('./../../../api/soalType');
const Matpel = require('./../../../api/matpel');
const Soal = require('./../../../api/soal');
const Jenjang = require('./../../../api/jenjang');
import {Circle8} from 'vue-loading-spinner'
import tinymce from 'vue-tinymce-editor'

export default {
    components: { Layout,Circle8, tinymce },
    page: {
        title: "Soal",
        meta: [{ name: "description", content: "List Of Schools Area" }]
    },
    data() {
        return {
            fieldSoal :[
                {
                    id:0,
                    soal : "<p>sdsd</p>",
                    jawaban : null,
                    isError : false,
                    errorMessage : "",
                    isEssay : false,
                    isFile : false,
                    imgPembahasan : false,
                    textPembahasan : "",
                    choices : [
                        {
                            id : "A",
                            value : "",
                            isError : false,
                            errorMessage : "",
                            isTrue : false,
                        },
                        {
                            id : "B",
                            value : "",
                            isError : false,
                            errorMessage : "",
                            isTrue : false,
                        },
                        {
                            id : "C",
                            value : "",
                            isError : false,
                            errorMessage : "",
                            isTrue : false,
                        },
                        {
                            id : "D",
                            value : "",
                            isError : false,
                            errorMessage : "",
                            isTrue : false,
                        }
                    ]
                }
            ],
            id:null,
            types : [],
            type : null,
            matpels : [],
            matpel : 0,
            name :'',
            loading : false,
            isError :false,
            message : '',
            Area : new Area(),
            soalType : new SoalType(),
            Matpel : new Matpel(),
            Soal : new Soal(),
            Jenjang : new Jenjang(),
            jenjangs : [],
            id_jenjang : 0
        }
    },
    computed: {
        editnya(){
            return this.$store.getters['soal/getId'];
        },
    },
    mounted(){
        this.getType();
        this.getMatpel();
        this.getJenjang();
    },
    methods: {
        getType(){
            this.soalType.data(this.matpel)
            .then((res)=>{
                if(!res.success){
                    this.setError(true,res.data)
                }else{
                    this.types = res.data_soal_type.data;
                }
            })
            .catch((err)=>{
                this.setError(true,err);
            })
        },
        getJenjang(){
            this.Jenjang.data()
            .then((res)=>{
                if(!res.success){
                    this.setError(true,res.data)
                }else{
                    this.jenjangs = res.data;
                }
            })
            .catch((err)=>{
                this.setError(true,err);
            })
        },
        getMatpel(){
            this.Matpel.data(this.id_jenjang)
            .then((res)=>{
                if(!res.success){
                    this.setError(true,res.data)
                }else{
                    this.matpels = res.data.data;
                }
            })
            .catch((err)=>{
                this.setError(true,err);
            })
        },
        TambahSoal(){
            this.fieldSoal.push({
                    id:this.fieldSoal.length,
                    soal : "",
                    jawaban : null,
                    isError : false,
                    errorMessage : "",
                    isEssay : false,
                    isFile : false,
                    pembahasan : null,
                    imgPembahasan : false,
                    textPembahasan : "",
                    choices : [
                        {
                            id : "A",
                            value : "",
                            isError : false,
                            errorMessage : "",
                            isTrue : false,
                        },
                        {
                            id : "B",
                            value : "",
                            isError : false,
                            errorMessage : "",
                            isTrue : false,
                        },
                        {
                            id : "C",
                            value : "",
                            isError : false,
                            errorMessage : "",
                            isTrue : false,
                        },
                        {
                            id : "D",
                            value : "",
                            isError : false,
                            errorMessage : "",
                            isTrue : false,
                        }
                    ]
                })
        },
        hapusSoalList(index){
            this.fieldSoal.splice(index,1);
        },
        Simpan(){
          console.log(this.fieldSoal);
            if(this.matpel != null && this.type != null){
                this.loading = true
                this.Soal.validate(this.fieldSoal,this.matpel,this.type)
                .then((res)=>{
                    if(res.haveError){
                        this.loading = false
                        this.fieldSoal = res.newFields
                    }else{
                      if (res.isFiles == true) {
                        this.loading = false
                        this.$store.dispatch('soal/saveFile',{soal:this.fieldSoal,matpel:this.matpel,type:this.type})
                        this.$router.push({ name: 'Soal' })
                      }else{
                        this.loading = false
                        this.$store.dispatch('soal/save',{soal:this.fieldSoal,matpel:this.matpel,type:this.type})
                        this.$router.push({ name: 'Soal' })

                      }
                    }
                })
                .catch(e=>{
                    this.loading = false;
                    console.log(e)
                })
            }else{
                this.$toasted.error("Matpel dan Paket wajib Dipilih", {
                    theme: "toasted-primary",
                    position: "bottom-right",
                    duration : null
                });
            }
        },
        back(){
            this.$router.push({ name: 'Soal' })
        },
        uploadImage(event,id){
            this.fieldSoal[id].pembahasan = event.target.files[0];
        },
        uploadImageSoal(event){
            var formData = new FormData();
            formData.append('picture',event.target.files[0]);
            this.loading  = true;
            this.Soal.UploadFile(formData)
            .then(res=>{
                if(res.success){
                    this.fieldSoal[0].soal += `&nbsp;<img src="http://103.41.207.247:3000/${res.data}" alt="${res.data}" width="120" height="100" />`
                }else{
                this.$toasted.error("Upload Images Failed, Please Try Again Later", {
                    theme: "toasted-primary",
                    position: "bottom-right",
                    duration : null
                });
                }
            this.loading  = false;
            this.$bvModal.hide('modal-image')
            })
            .catch(e=>{
            this.$bvModal.hide('modal-image')
                this.loading = false;
                console.log(e)
            })
        }
    },
}
</script>

<template>
  <Layout>
    <div v-if="loading" class="loading">
        <Circle8 size="70px"></Circle8>
    </div>
    <div class="row">
        <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
            <h4 class="mb-0 font-size-18">Add New Soal</h4>
        </div>
        </div>
    </div>
    <div class="row">
        <button class="float btn btn-warning btn-gallery" v-b-modal.modal-image v-b-tooltip.hover title="Add Image to Soal"><i class="bx bx-image-alt font-size-18"></i></button>
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <b-alert variant="danger" :show="isError">{{message}}</b-alert>
                    <div class="row  d-flex flex-column justify-content-start align-items-start">
                        <div class="col-md-5">
                            <div class="form-group">
                                <label class="control-label">Jenjang <span class="text-danger">*</span></label>
                                <select class="form-control" v-model="id_jenjang" @change="getMatpel()">
                                    <option :value="null" hidden>Select Jenjang</option>
                                    <option v-for="item in jenjangs" :key="item.id" :value="item.id">{{item.jenjang}}</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label class="control-label">Mata Pelajaran <span class="text-danger">*</span></label>
                                <select class="form-control" v-model="matpel" @change="getType()">
                                    <option :value="null" hidden>Select Mata Pelajaran</option>
                                    <option v-for="item in matpels" :key="item.id" :value="item.id">{{item.nama}}</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label class="control-label">Paket Soal <span class="text-danger">*</span></label>
                                <select class="form-control" v-model="type">
                                    <option :value="null" hidden>Select Paket Soal</option>
                                    <option v-for="item in types" :key="item.id" :value="item.id">{{item.type}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card" v-for="(item,index) in fieldSoal" :key="item.id+index">
                <div class="card-body">
                    <b-alert variant="danger" :show="item.isError">{{item.errorMessage}}</b-alert>
                    <div class="d-flex flex-row justify-content-between">
                        <!-- <h4 class="card-title">Soal Details Nomor ke {{index+1}}</h4> -->
                        <!-- <button class="btn btn-xs btn-danger" @click="hapusSoalList(index)"><i class="fas fa-times"></i> delete</button> -->
                    </div>
                    <div class="form-group">
                        <label class="control-label">Question : <span  class="text-danger">*</span></label>
                        <tinymce :id="`editor-${index}`" class="editor" height="500px"  v-model="item.soal" ></tinymce>
                    </div>
                    <div class="form-check mb-3">
                        <input class="form-check-input" v-model="item.isEssay" type="checkbox" value id="defaultCheck1" />
                        <label class="form-check-label" for="defaultCheck1">Centang Jika Ini Soal Essay</label>
                    </div>
                    <div class="form-check mb-3">
                        <input class="form-check-input" v-model="item.isFile" type="checkbox" value id="defaultCheck2" />
                        <label class="form-check-label" for="defaultCheck2">Centang Jika Ini Soal File</label>
                    </div>
                    <div v-if="!item.isFile">
                      <div class="form-group" v-if="item.isEssay">
                        <label class="control-label">Answer : <span class="text-danger">*</span></label>
                        <textarea rows="6" class="form-control" v-model="item.jawaban"></textarea>
                      </div>
                      <div class="form-group" v-if="!item.isEssay">
                        <b-input-group class="my-2" v-for="(choice,k) in item.choices" :key="choice.id+k">
                          <b-alert variant="danger" :show="choice.isError">{{choice.errorMessage}}</b-alert>
                          <template #prepend>
                            <b-input-group-prepend is-text>{{choice.id}}</b-input-group-prepend>
                          </template>
                          <b-form-input v-model="choice.value"></b-form-input>
                          <template #append>
                            <b-input-group-prepend is-text>
                              <b-form-radio v-model="item.jawaban" :name="`radio-${k}`" :value="k"></b-form-radio>
                            </b-input-group-prepend>
                          </template>
                        </b-input-group>
                      </div>
                      <div class="form-check mb-3">
                        <input class="form-check-input" v-model="item.imgPembahasan" type="checkbox" value id="isPembahasan" />
                        <label class="form-check-label" for="isPembahasan">Centang Jika Pembahasan tidak berbentuk Image</label>
                      </div>
                      <div class="form-group" v-if="item.imgPembahasan">
                        <label class="control-label">Pembahasan : <span  class="text-danger">*</span></label>
                        <tinymce :id="`editorpem-${index}`" class="editor" height="500px"  v-model="item.textPembahasan" ></tinymce>
                      </div>
                      <div class="form-group" v-else>
                        <label class="control-label">Pembahasan : <span class="text-danger">*</span></label><br>
                        <input type="file" accept="image/*" @change="uploadImage($event,index)" :id="`file-input-${item.id}`">
                      </div>

                    </div>
                </div>
            </div>
            <div class="form-group d-flex flex-row justify-content-between">
                <div>
                    <button class="btn btn-danger mx-2 " @click="back()">Kembali</button>
                    <button class="btn btn-primary mx-2" @click="Simpan()">Simpan</button>
                </div>
                <!-- <button class="btn btn-success flota-right" @click="TambahSoal()">Tambah Soal</button> -->
            </div>
                <b-modal id="modal-image" size="md" title="Upload Image For Insert to Soal">
                        <input type="file" accept="image/*" @change="uploadImageSoal($event)" id="fileSoal">
                    <template #modal-footer="{ ok, cancel }">
                        <!-- Emulate built in modal footer ok and cancel button actions -->
                        <b-button size="sm" variant="success" @click="ok()">
                           <i class="bx bx-upload"></i> UPLOAD
                        </b-button>
                        <b-button size="sm" variant="danger" @click="cancel()">
                            Cancel
                        </b-button>
                    </template>
                </b-modal>
        </div>
    </div>
  </Layout>
</template>
